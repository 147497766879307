<template>
    <div class="resourceDownload">
        <div class="resourceDownload-title">
            {{ $t('resourceDownLoad') }}
        </div>
        <div class="resourceDownload-list">
            <div class="resourceDownload-item" v-for="(v,i) in downloadList" :key="i">
                <div class="item-name">
                    {{ $i18n.locale=='zh'?v.name_zh:v.name_en }}
                </div>
                <div class="resourceDownload-detail">
                    <div class="detail-left">
                        <p>{{ $t('downloadVersion') }}:{{ v.desc }}</p>
                        <p>{{ $t('downloadSize') }}: {{v.size/1000}}MB</p>
                        <!-- <p>更新日期:<p>{{ v.dt.replace('T',' ').replace('+',' ') }}</p></p> -->
                        <p>{{ $t('downloadUpdat') }}:<p>{{ v.dt.split('T')[0]}}</p></p>
                    </div>
                    <div class="detail-right">
                        <a :href="v.url"  target="_blank">
                            <img src="@/assets/img/download.png" alt="" />
                            {{ $t('download') }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import API from '@/utils/request.js';
import  {useI18n } from 'vue-i18n';
import AOS from "aos";
export default {
    name:'index',
    data(){
        return{
            downloadList:[],
        }
    },
    mounted(){
        this.getDownloadList()
    },
    methods:{
         // 热门产品
         getDownloadList(){
            API.downloadList().then(res=>{
                if(res.code==200){
                  this.downloadList = res.data;
                }
            })
        },
    },
}
</script>
<style lang="scss">
.resourceDownload{
    width: 1200px;
    margin: 0 auto;
    &-title{
        font-size: 70px;
        line-height: 101px;
        width: 280px;
        position: relative;
        text-align: center;
        margin: 109px auto 111px;
        font-weight: bold;
        color: #273443;
    }
    &-title::before{
        position: absolute;
        top: 50.5px;
        left: -500px;
        width: 411.5px;
        height: 1px;
        background-color: #424D5A;
        display: block;
        content: '';
    }
    &-title::after{
        position: absolute;
        top: 50.5px;
        right: -500px;
        width: 411.5px;
        height: 1px;
        background-color: #424D5A;
        display: block;
        content: '';
    }
    &-list{
        width: 100%;
        @include display(top,between);
        margin: 109px 0 137px;
        flex-wrap: wrap;
        .resourceDownload-item{
            width: 588.75px;
            height: 425.77px;
            background-color: #F0F4FA;
            padding: 70px 30.75px 49.61px 46.5px;
            box-sizing: border-box;
            margin-bottom: 29.3px;
            .item-name{
                font-size: 34px;
                line-height: 49px;
                font-weight: bold;
                color: #273443;
            }
            .resourceDownload-detail{
                @include display(bottom,between);
                .detail-left{
                    width: 231.75px;
                    font-size: 30px;
                    line-height: 43px;
                    color: #273443;
                    opacity: .5;
                    margin-top: 51px;
                    p{
                        display: flex;
                        flex-wrap: wrap;
                    }
                }
                .detail-right{
                    width: 165px;
                    height: 64px;
                    background-color: #E15100;
                    margin-bottom: 51px;
                    a{
                        width: 100%;
                        height: 100%;
                        list-style: none;
                        text-decoration: none;
                        @include display(center,center);
                        font-size: 32px;
                        color: #fff;    
                        img{
                            width: 26px;
                            height: 32px;
                            margin-right: 20px;
                        }
                    }
                    
                }
            }
        }
    }
}
</style>